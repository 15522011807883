// ------------ import external dependencies -----------
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import styled from "styled-components";

interface BtnProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children?: React.ReactNode;
  text?: string;
  size?: string;
}

function Btn({ children, text, size, ...rest }: BtnProps) {
  return (
    <>
      {/* @ts-ignore */}
      <BtnWrapper {...rest} size={size}>
        {children ? children : text}
      </BtnWrapper>
    </>
  );
}

export default Btn;

const BtnWrapper = styled.button`
  min-height: ${(props: any) => (props.size === "sm" ? "36px" : props.size === "md" ? "50px" : "60px")};
  min-width: ${(props: any) => (props.size === "sm" ? "8rem" : "200px")};
  border: none;
  border-radius: 8px;
  font-size: ${(props: any) => (props.size === "sm" ? "0.875rem" : props.size === "md" ? "1rem" : "1.125rem")};
  font-weight: 600;
  color: var(--neutralWhite);
  background: var(--accent-2);
  display: block;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    background: var(--alternateLight);
    cursor: not-allowed;
  }
`;
