// ------------- import external dependencies -------------
import { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowRight, MdOutlinePushPin, MdPushPin } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

// ------------ import internal dependencies ----------
import { ReactComponent as LogoIcon } from "../../../../assets/img/logo-icon.svg";
import { ReactComponent as SideLogo } from "../../../../assets/img/logo-white.svg";
import { ReactComponent as AccessManagement } from "../../../../assets/svgs/access.svg";
import { ReactComponent as Balance } from "../../../../assets/svgs/balance.svg";
import { ReactComponent as Broadcasts } from "../../../../assets/svgs/broadcasts.svg";
import { ReactComponent as Category } from "../../../../assets/svgs/category.svg";
import { ReactComponent as OtherTransaction } from "../../../../assets/svgs/other-transaction.svg";
import { ReactComponent as Dashboard } from "../../../../assets/svgs/overview.svg";
import { ReactComponent as Payment } from "../../../../assets/svgs/payment.svg";
import { ReactComponent as QCP } from "../../../../assets/svgs/qcp.svg";
import { ReactComponent as Rates } from "../../../../assets/svgs/rates.svg";
import { ReactComponent as Report } from "../../../../assets/svgs/reports.svg";
import { ReactComponent as Settings } from "../../../../assets/svgs/settings.svg";
import { ReactComponent as Transactions } from "../../../../assets/svgs/transaction.svg";
import { ReactComponent as UserManagement } from "../../../../assets/svgs/user-mgt.svg";
import { ReactComponent as VBA } from "../../../../assets/svgs/vba.svg";
import PermissionChecker from "../../../../components/PermisionWrapper";

interface MenuItem {
  title: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  path: string;
  permission?: string | { [k: string]: { [j: string]: string } };
  open?: boolean;
  children?: any[];
}

function createObject<T>(keys: string[], values: T[]): { [key: string]: T } {
  let result: { [key: string]: T } = {};

  if (keys.length !== values.length) {
    return result;
  }

  keys.forEach((key, index) => {
    result[key] = values[index];
  });

  return result;
}

const userDataObj = createObject(
  ["title", "path", "userRole", "permission"],
  ["User Data", "users", ["superAdmin", "subAdmin"], { user_management: { access: "read" } }]
);
const userRankObj = createObject(
  ["title", "path", "userRole", "permission"],
  ["User Rank", "user-rank", ["superAdmin", "subAdmin"], { user_management: { access: "read" } }]
);
const userEmailsObj = createObject(
  ["title", "path", "userRole", "permission"],
  ["User Emails", "user-emails", ["superAdmin", "subAdmin"], { user_management: { access: "read" } }]
);
const referralsObj = createObject(
  ["title", "path", "userRole", "permission"],
  ["Referrals", "referrals", ["superAdmin", "subAdmin"], { user_management: { access: "read" } }]
);
const manualRewardsObj = createObject(
  ["title", "path", "userRole", "permission"],
  ["Manual Rewards", "manual-rewards", ["superAdmin", "subAdmin"], { user_management: { access: "read" } }]
);
const customerRetentionObj = createObject(
  ["title", "path", "userRole", "permission"],
  ["Customer Retention", "crd", ["superAdmin", "subAdmin"], { user_management: { access: "read" } }]
);
const kycDataObj = createObject(["title", "path", "userRole"], ["KYC Data", "kyc", ["superAdmin"]]);

const negativeSummationObj = createObject(
  ["title", "path", "userRole", "permission"],
  [
    "Negative Summation",
    "negative-summation",
    ["superAdmin", "subAdmin"],
    {
      user_management: { access: "write" },
      team_lead: { access: "write" },
      asst_team_lead: { access: "write" },
    },
  ]
);
const flaggedUsersObj = createObject(
  ["title", "path", "userRole", "permission"],
  [
    "Flagged Users",
    "flagged",
    ["superAdmin", "subAdmin"],
    {
      user_management: { access: "write" },
      team_lead: { access: "write" },
      asst_team_lead: { access: "write" },
    },
  ]
);
const deletedUsersObj = createObject(
  ["title", "path", "userRole", "permission"],
  [
    "Deleted Users",
    "deleted",
    ["superAdmin", "subAdmin"],
    {
      user_management: { access: "write" },
      team_lead: { access: "write" },
      asst_team_lead: { access: "write" },
    },
  ]
);

// ------- sidebar navigation menus --------
const menus: MenuItem[] = [
  {
    title: "Overview",
    icon: Dashboard,
    path: "dashboard",
    permission: "global",
  },
  {
    title: "Reports",
    icon: Report,
    path: "reports",
    permission: { reports: { access: "read" } },
  },
  {
    title: "Balance Edits",
    icon: Balance,
    path: "balance-edits",
    permission: { team_lead: { access: "read" }, other_transaction_management: { access: "read" } },
  },
  {
    title: "User Management",
    icon: UserManagement,
    path: "user-management",
    open: false,
    permission: { user_management: { access: "read" } },
    children: [
      {
        ...userDataObj,
      },
      {
        ...userRankObj,
      },
      {
        ...referralsObj,
      },
      {
        ...negativeSummationObj,
      },
      {
        ...userEmailsObj,
      },
      {
        ...manualRewardsObj,
      },
      {
        ...customerRetentionObj,
      },
      {
        ...kycDataObj,
      },
      {
        ...flaggedUsersObj,
      },
      {
        ...deletedUsersObj,
      },
    ],
  },
  {
    title: "Access Management",
    icon: AccessManagement,
    path: "access-management",
    permission: { team_lead: { access: "read" } },
    children: [
      {
        title: "Access",
        path: "users",
        userRole: ["superAdmin", "subAdmin"],
        permission: { user_management: { access: "read" } },
      },
      {
        title: "Partners",
        path: "partners",
        userRole: ["superAdmin", "subAdmin"],
        permission: { user_management: { access: "write" } },
      },
    ],
  },
  {
    title: "Category Management",
    icon: Category,
    path: "category-management",
    permission: { category_management: { access: "read" } },
  },
  {
    title: "Rates Management",
    icon: Rates,
    path: "rates",
    open: false,
    permission: { rates_management: { access: "read" } },
    children: [
      {
        title: "Rates",
        path: "card-rates",
      },
      {
        title: "Targets",
        path: "rates-targets",
      },
    ],
  },
  {
    title: "QCP Management",
    icon: QCP,
    path: "qcp-management",
    open: false,
    permission: { qcp_management: { access: "read" } },
    children: [
      {
        title: "All",
        path: "all",
      },
      {
        title: "Approved",
        path: "approved",
      },
      {
        title: "Rejected",
        path: "rejected",
      },
      {
        title: "Flagged",
        path: "flagged",
      },
      {
        title: "Cleared",
        path: "cleared",
      },
      {
        title: "Cleared-Approved",
        path: "cleared-approved",
      },
      {
        title: "Cleared-Rejected",
        path: "cleared-rejected",
      },
    ],
  },
  {
    title: "Transaction Management",
    icon: Transactions,
    path: "transaction-management",
    open: false,
    permission: { transaction_management: { access: "read" } },
    children: [
      {
        title: "All Orders",
        path: "all",
        userRole: ["superAdmin", "subAdmin"],
        permission: { user_management: { access: "write" } },
      },
      { title: "Archived Orders", path: "archived" },
      { title: "Completed", path: "completed" },
      { title: "Pending", path: "pending" },
      { title: "Rejected", path: "rejected" },
      { title: "Rejection Reason", path: "rejected-reason" },
      { title: "Transaction Ratings", path: "transaction-ratings" },
    ],
  },
  {
    title: "VBA Top-Ups",
    icon: VBA,
    path: "vba",
    open: false,
    permission: { payment_management: { access: "read" } },
    children: [
      { title: "All", path: "all" },
      { title: "Settled", path: "settled" },
      { title: "Declined", path: "declined" },
      { title: "Flagged", path: "flagged" },
      { title: "Resolve Top-up", path: "resolve" },
    ],
  },
  {
    title: "Payment Management",
    icon: Payment,
    path: "payment-management",
    open: false,
    permission: { payment_management: { access: "read" } },
    children: [
      {
        title: "Pending",
        path: "pending",
        open: false,
        children: [
          { title: "Pending", path: "all" },
          { title: "Pending (+50)", path: "pending+50" },
          { title: "Pending (-50)", path: "pending-50" },
        ],
      },
      {
        title: "Rejected",
        path: "rejected",
      },
      {
        title: "Approved",
        path: "approved",
      },
      {
        title: "Reversed",
        path: "reversed",
      },
      {
        title: "Processing",
        path: "processing",
      },
      {
        title: "Rejection Reason",
        path: "reasons",
      },
    ],
  },
  {
    title: "Other Transactions",
    icon: OtherTransaction,
    path: "other-transactions",
    permission: {
      other_transaction_management: { access: "read" },
    },
    children: [
      {
        title: "Gift Card",
        path: "gift-card",
        userRole: ["superAdmin", "subAdmin"],
        permission: { user_management: { access: "read" }, other_transaction_management: { access: "read" } },
        open: false,
        children: [
          { title: "Gift Card Purchase", path: "purchase" },
          { title: "Settings", path: "settings" },
        ],
      },
      {
        title: "Bills",
        path: "bills-history",
        userRole: ["superAdmin", "subAdmin"],
        permission: { settings: { access: "read" }, other_transaction_management: { access: "read" } },
      },
      {
        title: "Just Gadgets",
        path: "just-gadgets",
        userRole: ["superAdmin", "subAdmin"],
        permission: { other_transaction_management: { access: "read" } },
        open: false,
        children: [
          { title: "All", path: "all" },
          { title: "Confirmed", path: "confirmed" },
          { title: "Shipped", path: "shipped" },
          { title: "Delivered", path: "delivered" },
          { title: "Available Products", path: "available-products" },
        ],
      },
      {
        title: "Virtual Dollar",
        path: "cards",
        userRole: ["superAdmin", "subAdmin"],
        permission: { other_transaction_management: { access: "read" } },
        open: false,
        children: [
          { title: "Card Creation", path: "creation" },
          { title: "Transactions", path: "transactions" },
        ],
      },
    ],
  },
  {
    title: "Notifications",
    icon: Broadcasts,
    path: "notifications",
    permission: { contact_users: { access: "read" } },
  },
  {
    title: "Settings",
    icon: Settings,
    path: "settings",
    permission: { settings: { access: "read" }, team_lead: { access: "read" } },
    children: [
      {
        title: "Quick Settings",
        path: "quick-settings",
        userRole: ["superAdmin", "subAdmin"],
        permission: { settings: { access: "read" }, team_lead: { access: "read" } },
      },
      {
        title: "Site Management",
        path: "site-management",
        userRole: ["superAdmin", "subAdmin"],
        permission: { settings: { access: "read" } },
      },
      {
        title: "Fee Management",
        path: "fee-management",
        userRole: ["superAdmin", "subAdmin"],
        permission: { settings: { access: "read" } },
      },
      {
        title: "Content Management",
        path: "content-management",
        userRole: ["superAdmin", "subAdmin"],
        permission: { settings: { access: "read" } },
      },
      {
        title: "Bills Management",
        path: "bills-management",
        userRole: ["superAdmin", "subAdmin"],
        permission: { settings: { access: "read" } },
      },
    ],
  },
];

interface Props {
  minimize?: boolean;
  setMinimize?: (val: boolean) => void;
}

function SideBar({ minimize = false, setMinimize }: Props) {
  // ----- component state mmanagers ------
  const [menu, setMenu] = useState(menus);
  const [stickyState, setStickyState] = useState(false);

  // ------- intialize custom components ------
  const location = useLocation();

  const toggleMenu = (pos: number, subMenu?: boolean, childPos?: number) => {
    const menuCopy = [...menu];
    // const selectedMenu = subMenu ? (menuCopy[pos].children as any)[childPos as number] : menuCopy[pos].children;

    // ----- skip this if submenu -----
    if (!subMenu && menuCopy[pos].children) {
      menuCopy.forEach((ele, ind) => {
        if (pos === ind) {
          ele.open = !ele.open;
        } else {
          ele.open = false;
        }
      });
    }

    if (subMenu && (menuCopy[pos].children as any)[childPos as number]) {
      (menuCopy[pos].children as any).forEach((ele: any, ind: number) => {
        if (childPos === ind) {
          ele.open = !ele.open;
        } else {
          ele.open = false;
        }
      });
    }

    setMenu(menuCopy);
  };

  const checkIfActive = (ind: number) => {
    const { pathname } = location;
    const splitPath = pathname.split("/");
    return splitPath[ind];
  };

  return (
    <SideNavWrapper
      className={minimize ? "w-[90px]" : ""}
      onMouseLeave={() => {
        if (stickyState && !minimize) {
          setMinimize?.(true);
        }
      }}
    >
      {minimize ? (
        <LogoIcon
          className="my-10 ml-2 h-[25px]"
          onMouseEnter={() => {
            if (minimize) {
              setMinimize?.(false);
            }
          }}
        />
      ) : (
        // <img src={LogoWhite} className="mb-12 ml-4 mt-2 h-[25px]" alt="Cardtionic Brand favicon" />
        <LogoWrapper>
          <SideLogo aria-label="Cardtonic Brand Logo" className="h-[25px]" />
          <HamburgerControl
            // @ts-ignore
            pinned={true}
            onClick={() => {
              if (stickyState) {
                setMinimize?.(false);
                setStickyState(false);
              } else {
                setMinimize?.(!minimize);
                setStickyState(!stickyState);
              }
            }}
          >
            {stickyState ? <MdOutlinePushPin /> : <MdPushPin />}
          </HamburgerControl>
          {/* <img src={LogoLight} alt="Cardtonic Brand Logo" className="h-[25px]" /> */}
        </LogoWrapper>
      )}

      <nav>
        <ul className="list-none pl-0">
          {menu.map((ele: MenuItem, ind: number) => (
            <PermissionChecker
              key={ind}
              userRole={["superAdmin", "subAdmin"]}
              permission={ele.permission}
              // access="read"
            >
              <ListItem key={ind}>
                <div className="flex justify-between items-center" onClick={() => toggleMenu(ind)}>
                  {ele.children ? (
                    // ------- render menu with children route -----
                    <a href="#" className={checkIfActive(2) === ele.path ? "opacity-100 active" : "opacity-50"}>
                      <ele.icon />
                      {!minimize ? <span>{ele.title}</span> : null}
                    </a>
                  ) : (
                    // ------ render menu with no children ------
                    <NavLink
                      to={`/admin/${ele.path}`}
                      className={({ isActive }) => (isActive ? "opacity-100 active" : "opacity-50")}
                    >
                      <ele.icon />
                      {!minimize ? <span>{ele.title}</span> : null}
                    </NavLink>
                  )}

                  {/* ------ check if menu has children and it is opened ------ */}
                  {ele.children &&
                    (ele.open ? (
                      <MdKeyboardArrowDown className="opacity-100 text-2xl mr-4 text-white-neutral" />
                    ) : (
                      <MdKeyboardArrowRight className="opacity-50 text-2xl mr-4 text-white-neutral" />
                    ))}
                </div>

                {/* ------ children content ------ */}
                {!minimize && ele.open && ele.children && (
                  <ul className="list-none">
                    {ele.children.map((subele: any, index: number) => (
                      <PermissionChecker userRole={subele?.userRole} permission={subele?.permission}>
                        <SubListItem key={index}>
                          {/* ------ render sub-menu with children route ------ */}
                          {subele.children ? (
                            <a
                              href="#"
                              className={
                                checkIfActive(3) === subele.path ? "submenu--list opacity-100 active" : `submenu--list`
                              }
                              onClick={() => toggleMenu(ind, true, index)}
                            >
                              <span>{subele.title}</span>

                              {subele.children &&
                                (subele.open ? (
                                  <MdKeyboardArrowDown className="opacity-100 text-2xl mr-4 text-white-neutral" />
                                ) : (
                                  <MdKeyboardArrowRight className="opacity-50 text-2xl mr-4 text-white-neutral" />
                                ))}
                            </a>
                          ) : (
                            // ------ render sub-menu with no children route ------
                            <NavLink
                              to={`/admin/${ele.path}/${subele.path}`}
                              className={({ isActive }) => (isActive ? "opacity-100 active" : "opacity-50")}
                            >
                              {subele?.title}
                            </NavLink>
                          )}

                          {subele.open && subele.children && (
                            <ul className="list-none">
                              {subele.children.map((subChildElem: any, subInd: number) => (
                                <SubListItem key={subInd}>
                                  <NavLink
                                    to={`/admin/${ele.path}/${subele.path}/${subChildElem.path}`}
                                    className={({ isActive }) =>
                                      isActive ? "opacity-100 active child" : "opacity-50 child"
                                    }
                                  >
                                    {subChildElem?.title}
                                  </NavLink>
                                </SubListItem>
                              ))}
                            </ul>
                          )}
                        </SubListItem>
                      </PermissionChecker>
                    ))}
                  </ul>
                )}
              </ListItem>
            </PermissionChecker>
          ))}
        </ul>
      </nav>
    </SideNavWrapper>
  );
}

export default SideBar;

// ------ component styles ------
const SideNavWrapper = styled.aside`
  width: 280px;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  background: var(--navigationBg);
  overscroll-behavior-y: contain;
  overflow: auto;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const HamburgerControl = styled.span`
  display: block;
  cursor: pointer;

  & > svg {
    font-size: 1.5rem;
    & > path:nth-child(2) {
      fill: ${(props: any) => (props.pinned ? "var(--neutralWhite) " : "none")};
    }
  }
`;

const ListItem = styled.li`
  margin-bottom: 2rem;

  a {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.875rem;
    color: #ffffff;
    font-weight: 600;
    padding-left: 1.5rem;
    transition: 0.5s ease-in all;

    > span {
      color: #ffffff;
      font-size: 0.875rem;
      // font-weight: 400;
    }

    &.active {
      position: relative;
      &::before {
        content: "";
        background: var(--accent-1);
        width: 5px;
        height: 40px;
        border-radius: 5px;
        left: 0;
        display: block;
        position: absolute;
      }
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

const SubListItem = styled.li`
  a {
    display: block;
    font-size: 0.875rem;
    color: #ffffff;
    font-weight: 700;
    padding: 1rem 2rem;

    &.submenu--list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0;
      opacity: 0.5;
    }

    &:last-child {
      padding-bottom: 0;
    }

    > span {
      color: #ffffff;
    }

    &.active {
      position: relative;
      &::before {
        content: "";
        background: var(--accent-1);
        width: 5px;
        height: 5px;
        border-radius: 5px;
        left: -5px;
        display: block;
        position: absolute;
        bottom: 7px;
      }
    }
  }
`;
